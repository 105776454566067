<template>
	<WSection :fill-height="$vuetify.breakpoint.mdAndUp" :title="$t('ecm-catalog.ecm_settings') + ' :'">
		<v-layout fill-height column>
			<v-flex shrink>
				<w-text-info :text="$t('ecm-catalog.settings_info')" />
			</v-flex>
			<v-flex scroll-y>
				<v-layout fill-height row wrap>
					<v-flex xs12 md6>
						<v-layout column>
							<v-subheader v-text="$t('ecm-catalog.accounting_firm') + ' :'" />
							<v-layout ml-4 row>
								<v-flex v-t="'ecm-catalog.accounting_firms_ecm'" shrink />
								<v-flex class="pointer primary--text" pl-2 shrink @click="selectTreeStructure(accountingFirmsEcm)" v-text="accountingFirmsEcm.name" />
							</v-layout>
						</v-layout>
					</v-flex>
					<v-flex fill-height xs12 md6>
						<v-layout column fill-height>
							<v-subheader v-text="$t('ecm-catalog.customers') + ' :'" />
							<v-flex v-t="'ecm-catalog.ecms_list'" ml-4 />
							<v-flex scroll-y>
								<v-radio-group class="ml-4" :value="defaultTreeStructureId" @change="onChange">
									<v-layout v-for="ecm in availableEcm" :key="ecm.id" align-center row>
										<v-radio class="ma-0" color="primary" :value="ecm.id" />
										<v-flex @click="onChange(ecm.id)">
											{{ ecm.name }}
											<i v-if="ecm.is_default_for_customers" v-t="'ecm-catalog.by_default'" class="mr-2 primary--text" />
										</v-flex>
										<v-spacer />
										<v-tooltip bottom>
											<template v-slot:activator="{ on }">
												<v-btn class="ma-0" :disabled="ecm.is_default_for_customers" icon v-on="on" @click="removeTreeStructureFromList(ecm)">
													<v-icon color="primary">cancel</v-icon>
												</v-btn>
											</template>
											<span v-t="'ecm-catalog.remove_from_list'" />
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on }">
												<v-btn class="ma-0" icon v-on="on" @click="selectTreeStructure(ecm)">
													<v-icon color="primary">visibility</v-icon>
												</v-btn>
											</template>
											<span v-t="'ecm-catalog.show_ecm'" />
										</v-tooltip>
									</v-layout>
								</v-radio-group>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</WSection>
</template>

<script>
import ECMCatalogModuleGuard from '@/mixins/ModulesGuards/ECMCatalog/ECMCatalogModuleGuard'

export default {
	name: 'ECMCatalogSettings',
	mixins: [ECMCatalogModuleGuard],
	props: {
		accountingFirmsEcm: {
			required: false,
			type: Object,
			default: () => ({})
		},
		availableEcm: {
			required: false,
			type: Array,
			default: () => []
		}
	},
	computed: {
		defaultTreeStructureId: function () {
			let result = null
			const treeStructure = this.availableEcm.find(item => item.is_default_for_customers)
			if (treeStructure) {
				result = treeStructure.id
			}
			return result
		}
	},
	methods: {
		onChange: function (treeStructureId) {
			return this.service.setTreeStructureAsDefaultForCustomers(treeStructureId)
		},
		removeTreeStructureFromList: function (treeStructure) {
			return this.service.removeTreeStructureFromList(treeStructure)
		},
		selectTreeStructure: function (treeStructure) {
			this.eventBus.emit(this.events.TREE_STRUCTURE_SELECTED, treeStructure)
		}
	}
}
</script>
